import { ApplicationController } from 'stimulus-use';
import { patch } from '@rails/request.js';

export default class extends ApplicationController {
  async handleChange(event) {
    this.focusNextInput(event.target);
    const form = this.element.closest('form');
    const url = form.action;
    const formData = new FormData(form);

    // If food is not selected, do not send the request
    if (formData.get('annotation_item[food_id]') === '') return;

    await patch(url, {
      body: formData,
      responseKind: 'turbo-stream',
    });
  }

  focusNextInput(element) {
    if (element.tomselect == null) {
      return;
    }

    const nextInput = element
      .closest('.annotation-item')
      .querySelector('input[name="annotation_item[consumed_quantity]"]');
    if (nextInput) {
      nextInput.focus();
    }
  }
}
