import { ApplicationController } from 'stimulus-use';
import { destroy } from '@rails/request.js';

export default class extends ApplicationController {
  static get targets() {
    return [
      'form',
    ];
  }

  static get values() {
    return {
      selected: String,
      focus: String,
    };
  }

  static get classes() {
    return ['hidden'];
  }

  connect() {
    window.addEventListener('click', (e) => { this.deselectAll(e); }, false);
  }

  deselectAll(e) {
    const { target } = e;
    if (
      ![null, undefined, ''].includes(this.selectedValue)
        && !target.closest('.annotation-item')
        && !target.closest('#annotation_items--title')
    ) {
      this.selectedValue = '';
    }
  }

  selectItem(event) {
    // target is not the Destroy link
    const { target } = event;
    if (!target.closest('a[data-turbo-method="delete"]')) {
      const item = target.closest('div.annotation-item');
      const { id } = item.dataset;
      if (this.selectedValue !== id) {
        this.selectedValue = id;
      }
    }
    if (['input', 'select'].includes(target.tagName.toLowerCase())) {
      this.focusValue = target.id;
    }
  }

  selectedValueChanged() {
    this.selectItemById(this.selectedValue);
  }

  selectItemById(id) {
    this.formTargets.forEach((formTarget) => {
      if (formTarget.dataset.id === id && formTarget.getAttribute('data-selected') !== 'selected') {
        formTarget.setAttribute('selected', 'selected');
      } else {
        formTarget.removeAttribute('selected');
      }
    });
  }

  toggleButtons() {
    const destroyButton = this.element.querySelector('#destroy_annotation_items');
    const elements = this
      .element
      .querySelectorAll('input[name="annotations_selected_annotation_items[annotation_item_ids]"]:checked');
    if (elements.length > 0) {
      destroyButton.disabled = false;
    } else {
      destroyButton.disabled = true;
    }
  }

  destroyAnnotationItems(event) {
    event.preventDefault();
    const form = event.target.closest('form');
    const confirmMessage = form.dataset.turboConfirm;
    if (window.confirm(confirmMessage)) {
      const { action } = form;
      const elements = this
        .element
        .querySelectorAll('input[name="annotations_selected_annotation_items[annotation_item_ids]"]:checked');
      const values = [];
      elements.forEach((element) => {
        values.push(element.value);
      });

      destroy(action, {
        body: {
          annotations_annotation_items_destroy_form: {
            annotation_item_ids: values,
          },
        },
        responseKind: 'turbo-stream',
      });
    }
  }
}
